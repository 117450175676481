import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import Accordion from '../Accordion';

const Section = styled.section`
  // min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.h1`
  font-family: 'Akaya Telivigala', cursive;
  font-size: ${(props) => props.theme.fontxxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  // border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-content: center;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;

    & > *:last-child {
      & > *:first-child {
        margin-top: 0;
      }
    }
  }
`;
const Box = styled.div`
  width: 45%;
  @media (max-width: 64em) {
    align-self: center;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const Faq = () => {
  const ref = useRef(null);
  // gsap.registerPlugin(ScrollTrigger);
  // useLayoutEffect(() => {
  //   let element = ref.current;

  //   ScrollTrigger.create({
  //     trigger: element,
  //     start: 'bottom bottom',
  //     end: 'bottom top',
  //     pin: true,
  //     pinSpacing: false,
  //     scrub: 1,
  //     // markers:true,
  //   });

  //   return () => {
  //     ScrollTrigger.kill();
  //   };
  // }, []);

  return (
    <Section ref={ref} id='faq'>
      <Title>Faq</Title>

      <Container>
        <Box>
          <Accordion ScrollTrigger={ScrollTrigger} title='WHERE CAN I VIEW MY NFTS?'>
            The collection will be visible on Alto and our very own aquarium!
          </Accordion>
          <Accordion ScrollTrigger={ScrollTrigger} title='HOW CAN I USE MY NFT?'>
            Canto Catch will be playable in our upcoming gamefi game!
          </Accordion>
        </Box>
        <Box>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title='WHAT ARE Canto Catch?'
          >
            Canto Catch is a collection of 3333 Fish and Mermaids in a unique Sailor Jerry Tattoo inspired aesthetic!
          </Accordion>
          <Accordion
            ScrollTrigger={ScrollTrigger}
            title='WHEN WILL Canto Catch BE AVAILABLE TO MINT?'
          >
            Canto Catch is available to mint right now on the Canto Blockchain!
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default Faq;
