import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import DrawSvg from '../DrawSvg';

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  // background-color: ${(props) => props.theme.body};
  position: relative;
  display: inline-block;
  overflow: hidden;
`;
const Title = styled.h1`
  font-family: 'Akaya Telivigala', cursive;
  font-size: ${(props) => props.theme.fontxxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 0;
  // border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;
const Container = styled.div`
  width: 70%;
  height: 200vh;
  // background-color: ${(props) => props.theme.body};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 64em) {
    width: 80%;
  }
  @media (max-width: 48em) {
    width: 90%;
  }
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: lightblue; */

  @media (max-width: 48em) {
    width: 90%;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 48em) {
      justify-content: center;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 48em) {
        border-radius: 0 50px 0 50px;
        text-align: left;
        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 48em) {
      justify-content: center;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-end !important;
  }
`;
const ItemContainer = styled.div`
  width: 40%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid ${(props) => props.theme.text};

  @media (max-width: 48em) {
    width: 70%;
  }
`;

const Box = styled.p`
  height: fit-content;
  // background-color: ${(props) => props.theme.carouselColor};
  color: ${(props) => props.theme.text};
  padding: 1rem;
  position: relative;
  border: 1px solid ${(props) => props.theme.text};
`;
const SubTitle = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: ${(props) => props.theme.fontsm};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const RoadMapItem = ({ title, subtext, addToRef }) => {
  return (
    <Item ref={addToRef}>
      <ItemContainer>
        <Box>
          <SubTitle>{title} </SubTitle>
          <Text>{subtext}</Text>
        </Box>
      </ItemContainer>
    </Item>
  );
};

const Roadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: '0',
        },
        {
          y: '-30%',

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: 'top center+=200px',
            end: 'bottom center',
            scrub: true,
            // markers:true,
          },
        },
      );
    });

    return () => {
      if (t1) t1.kill();
    };
  }, []);

  return (
    <Section id='roadmap'>
      <div class='ocean'>
        <div class='bubble bubble--1'></div>
        <div class='bubble bubble--2'></div>
        <div class='bubble bubble--3'></div>
        <div class='bubble bubble--4'></div>
        <div class='bubble bubble--5'></div>
        <div class='bubble bubble--6'></div>
        <div class='bubble bubble--7'></div>
        <div class='bubble bubble--8'></div>
        <div class='bubble bubble--9'></div>
        <div class='bubble bubble--10'></div>
        <div class='bubble bubble--11'></div>
        <div class='bubble bubble--12'></div>
        {/* <div id='octocat'></div> */}
        <Title>Roadmap</Title>
        <Container>
          <SvgContainer>
            <DrawSvg />
          </SvgContainer>
          <Items>
            <Item>&nbsp;</Item>
            <RoadMapItem
              addToRef={addToRefs}
              title='Initial Mint'
              subtext='The first step in bringing Canto Catch to life is to mint the 3,333 unique pieces, ensuring each one is truly one-of-a-kind. This process involves creating each of the fish and mermaids, and making them available to mint on the Canto blockchain'
            />
            <RoadMapItem
              addToRef={addToRefs}
              title='Community Building'
              subtext='Once the minting process is complete, the next step is to build a passionate and engaged community. This will include hosting events and contests, and engaging with community members to help build the best gamefi community on Canto! '
            />
            <RoadMapItem
              addToRef={addToRefs}
              title='Gamefi'
              subtext='Integrating Canto Catch into the growing gamefi ecosystem will further increase the value of the collection, and make it more fun to hold. The first planned game (already in developement will allow you to race your fish and level up various attributes!'
            />
            <RoadMapItem
              addToRef={addToRefs}
              title='More Gamefi'
              subtext='Racing is just the start, as we plan to build a gamefi arcade where more games will be added and playable with your NFT!'
            />
            <RoadMapItem
              addToRef={addToRefs}
              title='And Beyond'
              subtext='The next step for Canto Catch will be to continue expanding and evolving the ecosystem, finding new and innovative ways to bring value to the collection and to Canto overall. This will include further developement of our games and other utilities.'
            />

          </Items>
        </Container>
      </div>
    </Section>
  );
};

export default Roadmap;
